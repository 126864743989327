import React, { useState } from "react"
import { Link } from "gatsby"
import Logo from "../images/SVG/logo.svg"

const Navigation = ({}) => {
  const [isExpanded, toggleExpansion] = useState(false)

  return (
    <nav className="w-full flex items-center justify-between flex-wrap p-4 border-b navigation">
      <div className="items-center flex-shrink-0 text-white mr-6">
        <Link to="/">
          <img src={Logo} alt="Sueña en Colores" style={{ width: "140px" }} />
        </Link>
      </div>

      <div className="block lg:hidden">
        <button
          className="items-center px-3 py-2 border rounded text-blue-600 border-blue-500 hover:text-black hover:border-black"
          onClick={() => toggleExpansion(!isExpanded)}
        >
          <svg
            className="fill-current h-3 w-3"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <title>Menu</title>
            <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
          </svg>
        </button>
      </div>

      <div
        className={`${
          isExpanded ? `block` : `hidden`
        } lg:flex lg:w-auto justify-end`}
      >
        <div className="lg:flex-grow md:items-end lg:items-end ml-8">
          <Link
            to="/"
            className="block mt-4 lg:inline-block lg:mt-0 text-teal-800 text-lg hover:text-black hover:underline mr-5"
            activeClassName="underline"
          >
            Inicio
          </Link>
          <Link
            to="/sobre-nosotros"
            className="block mt-4 lg:inline-block lg:mt-0 text-teal-800 text-lg hover:text-black hover:underline mr-5"
            activeClassName="underline"
          >
            Sobre Nosotros
          </Link>

          <Link
            to="/descargas"
            className="block mt-4 lg:inline-block lg:mt-0 text-teal-800 text-lg hover:text-black hover:underline mr-5"
            activeClassName="underline"
          >
            Descargas
          </Link>

          <Link
            to="/ejercicios"
            className="block mt-4 lg:inline-block lg:mt-0 text-teal-800 text-lg hover:text-black hover:underline mr-5"
            activeClassName="underline"
          >
            Mindfulness para niños
          </Link>

          <Link
            to="/donaciones"
            className="block mt-4 lg:inline-block lg:mt-0 text-teal-800 text-lg hover:text-black hover:underline mr-5"
            activeClassName="underline"
          >
            Donaciones
          </Link>

          <Link
            to="/contacto"
            className="block mt-4 lg:inline-block lg:mt-0 text-teal-800 text-lg hover:text-black hover:underline mr-5"
            activeClassName="underline"
          >
            Contacto
          </Link>
        </div>
      </div>
    </nav>
  )
}

export default Navigation
